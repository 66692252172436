import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import SuiTypography from "components/SuiTypography";
import RemoveIcon from "@mui/icons-material/Remove";
import SuiBox from "components/SuiBox";
import VideoPlayer from "components/VideoPlayer";
import Grid from "@mui/material/Grid";

export default function CustomizableAccordions({
  panels,
  plusIcon,
  setCurrentTab,
  subtitle,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState("0-0");

  useEffect(() => {
    if (subtitle) {
      return setExpanded(false);
    } else {
      return setExpanded("panel1");
    }
  }, [subtitle]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {subtitle && (
        <SuiTypography
          variant=""
          fontWeight="medium"
          fontSize={20}
          color={"dark"}
          textTransform={"capitalize"}
        >
          {subtitle}
        </SuiTypography>
      )}
      {panels?.map((panel, index) => (
        <Accordion
          defaultExpanded
          key={index}
          expanded={expanded === `panel${index + 1}`}
          onChange={handleChange(`panel${index + 1}`)}
          disableGutters
          elevation={false}
        >
          <AccordionSummary
            expandIcon={
              plusIcon ? (
                expanded ? (
                  <RemoveIcon sx={{ color: "#17c1e8" }} />
                ) : (
                  <AddIcon sx={{ color: "#17c1e8" }} />
                )
              ) : (
                <ExpandMoreIcon sx={{ color: "#17c1e8" }} />
              )
            }
            aria-controls={`panel${index + 1}bh-content`}
            id={`panel${index + 1}bh-header`}
          >
            <SuiBox>
              <SuiTypography
                variant=""
                fontWeight="medium"
                fontSize={15}
                color={"dark"}
                textTransform={subtitle ? "" : "capitalize"}
              >
                {panel?.title
                  ? panel?.title
                  : `${index + 1}. ${panel.question}`}
              </SuiTypography>
            </SuiBox>
          </AccordionSummary>
          <AccordionDetails>
            {panel.subtitles ? (
              panel.subtitles?.map((item, i) => {
                const currentItem = `${index}-${i}`;
                return (
                  <SuiBox pb={1} key={i}>
                    <SuiTypography
                      variant="h6"
                      fontWeight="regular"
                      fontSize={14}
                      color={"dark"}
                      sx={{ 
                        cursor: "pointer",
                        color: selectedItem === currentItem ? "#17c1e8" : "dark"
                      }}
                      onClick={() => {
                        setCurrentTab([item])
                        setSelectedItem(currentItem);
                      }}
                    >
                      {item.subtitle ? item.subtitle : item?.answer}
                    </SuiTypography>
                  </SuiBox>
                );
              })
            ) : (
              <>
                <SuiTypography
                  variant="h6"
                  fontWeight="regular"
                  fontSize={14}
                  color={"dark"}
                >
                  Answer: {panel?.answer}
                </SuiTypography>
                {panel?.url && (
                  <Grid container>
                    <Grid item xs={12} sm={7} xl={5}>
                      <SuiBox mt={2}>
                        <VideoPlayer url={panel?.url} />
                      </SuiBox>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
