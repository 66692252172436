import { useEffect, useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

import DashboardChart from "./sub-components/chart";

import { useDispatch, useSelector } from "react-redux";
import {
  DashboardSelector,
  getProductInventory,
  getServiceInventory,
  clearState,
} from "store/features/dashboard";

import { toast } from "react-toastify";

import { getUpDownStat, calculateProductFinalPrice } from "utils/common";
import { StatSelector, statisticState } from "store/features/statistics.js";
import { monthWiseClientsCount } from "firebaseConfig";
import { userSelector } from "store/features/authSlice";
import {
  ClientSelector,
  getClients,
  clearState as clientClearState,
} from "store/features/clients";
import moment from "moment";
import { updateFilterState } from "store/features/clients";
import { useNavigate } from "react-router-dom";
import AnniversariesAndBirthdays from "./sub-components/anniversariesAndBirthdays";
import Appointments from "./sub-components/appointments";
import Leads from "./sub-components/leads";
import Balance from "./sub-components/balance";
import Dietchart from "./sub-components/dietchart";
import WishModal from "./sub-components/modal";
import { fetchRenewables } from "services/dashboard";
import Renewables from "./sub-components/Renewable";
import { calculateTotal } from "utils/helper";
import { getExpenses } from "services/expense";
import EarningExpenseTracker from "./sub-components/EarningExpenseTracker";
import { calculateTotalForSales } from "utils/helper";

function Dashboard() {
  const { uid } = useSelector(userSelector);
  const [loader, setLoader] = useState(false);
  const [MiniCardData, setMiniCardData] = useState([]);
  const { isError, isSuccess, message } = useSelector(DashboardSelector);
  const {
    clients,
    isSuccess: isSuccess_,
    isError: isError_,
    message: message_,
  } = useSelector(ClientSelector);

  const {
    lastMonthClients,
    currentMonthClients,
    clientCount,
    lastMonthServiceSaleCount,
    currentMonthServiceSaleCount,
    currentMonthProductSaleCount,
    lastMonthProductSaleCount,
    balance,
  } = useSelector(StatSelector); //navbar stats

  const dispatch = useDispatch();

  //for clearing client state
  useEffect(() => {
    if (isSuccess_) {
      dispatch(clientClearState());
    }
    if (isError_) {
      toast.error(message_);
      dispatch(clientClearState());
    }
  }, [isSuccess_, message_, dispatch, isError_]);

  //gets inventory and client lists
  const fetchInventory = useCallback(async () => {
    try {
      setLoader(true);
      await dispatch(getProductInventory({ uid }));
      await dispatch(getServiceInventory({ uid }));
      await dispatch(getClients({ uid }));
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  }, [uid, dispatch]);
  useEffect(() => {
    fetchInventory();
  }, [fetchInventory]);

  // gets stats of topbar
  useEffect(() => {
    (async () => {
      let balance_ = 0;
      let serviceClientCount = 0;
      const today = moment();

      clients.map((item) => {
        let active = false;
        if (item.services) {
          item?.services.map((service) => {
            const isBetweenDates = today.isBetween(
              service.startDate,
              service.completionDate,
              null,
              "[]"
            );
            if (isBetweenDates) active = true;
            balance_ +=
              parseFloat(service.totalAmount ?? 0) -
              parseFloat(service.discount ?? 0) -
              parseFloat(service.amountPaid ?? 0);
            return service;
          });
        }

        if (item.consultation) {
          item?.consultation.map((service) => {
            balance_ +=
              parseFloat(service.totalAmount ?? 0) -
              parseFloat(service.discount ?? 0) -
              parseFloat(service.amountPaid ?? 0);
            return service;
          });
        }

        if (item?.products?.length > 0) {
          item?.products?.map((product) => {
            balance_ +=
              parseInt(product.quantity) *
                (
                  parseFloat(product?.sellingPriceWOT ?? 0) -
                  parseFloat(product?.discount ?? 0) +
                  parseFloat(
                    parseFloat(product?.sellingPriceWOT ?? 0) *
                      (parseFloat(product?.gst ?? 0) / 100)
                  )
                ).toFixed(2) -
              parseFloat(product.amountPaid ?? 0);
            return product;
          });
        }
        if (active) serviceClientCount++;
        return item;
      });

      let {
        lastMonthClients,
        currentMonthClients,
        lastMonthServiceSaleCount,
        currentMonthServiceSaleCount,
        currentMonthProductSaleCount,
        lastMonthProductSaleCount,
      } = await monthWiseClientsCount(uid);

      let payload = {
        lastMonthClients,
        currentMonthClients,
        clientCount: serviceClientCount,
        lastMonthServiceSaleCount,
        currentMonthServiceSaleCount,
        currentMonthProductSaleCount,
        lastMonthProductSaleCount,
        balance: balance_,
      };
      dispatch(statisticState(payload));
    })();
  }, [dispatch, uid, clients]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      dispatch(clearState());
    }
    if (isError) {
      toast.error(message);
      setLoader(false);
      dispatch(clearState());
    }
  }, [isSuccess, message, dispatch, isError]);

  let navigate = useNavigate(); //for navigation

  useEffect(() => {
    let MiniCardData_ = [
      {
        title: "Total Active Clients",
        count: clientCount,
        percent: {
          ...getUpDownStat(lastMonthClients, currentMonthClients),
        },
        icon: {
          color: "info",
          component: "help",
          para: "These are your total active service clients",
        },
      },
      {
        title: "Service Sales This Month",
        count: currentMonthServiceSaleCount,
        percent: {
          ...getUpDownStat(
            lastMonthServiceSaleCount,
            currentMonthServiceSaleCount
          ),
        },
        onTileClickHandler: () => {
          dispatch(updateFilterState({ state: "SERVICE", redirect: true }));
          navigate(`/clients`);
        },
        icon: {
          color: "info",
          component: "help",
          para: "This is your sales from services for the current month ",
        },
      },
      {
        title: "Product Sales This Month",
        count: currentMonthProductSaleCount,
        percent: {
          ...getUpDownStat(
            lastMonthProductSaleCount,
            currentMonthProductSaleCount
          ),
        },

        onTileClickHandler: () => {
          dispatch(updateFilterState({ state: "PRODUCT", redirect: true }));
          navigate(`/clients`);
        },
        icon: {
          color: "info",
          component: "help",
          para: "This is your product sales for the current month",
        },
      },
      {
        title: "Balance To Be Collected",
        count: <span style={{ color: "red" }}>{balance?.toFixed(2)}</span>,

        icon: {
          color: "info",
          component: "help",
          para: "Overall balance to be collected from Services & Product sales",
        },
      },
    ];
    setMiniCardData(MiniCardData_);
  }, [
    lastMonthClients,
    currentMonthClients,
    clientCount,
    currentMonthProductSaleCount,
    lastMonthProductSaleCount,
    currentMonthServiceSaleCount,
    lastMonthServiceSaleCount,
    balance,
    dispatch,
    navigate,
  ]);

  const data1 = {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Total amount",
        color: "info",
        data: [],
      },
      {
        label: "Balance",
        color: "dark",
        data: [],
      },
    ],
  };

  const data2 = {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Total product sold",
        color: "info",
        data: [],
      },
      {
        label: "Amount",
        color: "dark",
        data: [],
      },
    ],
  };

  const [serviceChartData, setServiceChartData] = useState(data1);
  const [earExpBalChartData, setEarExpBalChartData] = useState(data1);
  const [consultationChartData, setConsultationChartData] = useState(data1);
  const [productChartData, setProductChartData] = useState(data2);
  const [yearWiseServiceChartData, setYearWiseServiceChartData] = useState();
  const [yearWiseConsultationChartData, setYearWiseConsultationChartData] =
    useState();
  const [yearWiseProductChartData, setYearWiseProductChartData] = useState();
  const [serviceYearArray, setServiceYearArray] = useState([]);
  const [consultationYearArray, setConsultationYearArray] = useState([]);
  const [productYearArray, setProductYearArray] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalIncomings, setTotalIncomings] = useState(0);
  const [expensesChartData, setExpensesChartData] = useState({
    labels: Array.from({ length: 12 }, (_, i) =>
      moment().month(i).format("MMM")
    ),
    datasets: [{ label: "Total Expenses", color: "info", data: [] }],
  });
  const [balSerProConYearwiseArray, setBalSerProConYearwiseArray] = useState(
    []
  );
  const [incomingsYearwiseArray, setIncomingsYearwiseArray] = useState([]);
  const [totalBalSerProConYearwiseArray, setTotalBalSerProConYearwiseArray] =
    useState();
  const [yearWiseExpensesChartData, setYearWiseExpensesChartData] = useState();
  const [expensesYearArray, setExpensesYearArray] = useState([]);
  const [incomingYearArray, setIncomingYearArray] = useState([]);

  const dashboardStatHandler = useCallback(() => {
    let today = new Date();
    let purchasedAt;

    let yearServiceObj = {};
    let yearServiceArray_ = [];

    let yearConsultationObj = {};
    let yearConsultationArray_ = [];

    let yearProductObj = {};
    let yearProductArray_ = [];

    clients.map((item) => {
      if (item.services) {
        item.services.map((item_) => {
          let startDate = moment(item_.startDate);
          let month = Number(startDate.format("MM"));
          let year = Number(startDate.format("YYYY"));
          function convertDate(startDate)
          {
            const formats = [
              "YYYY-MM-DD", 
              "DD-MM-YYYY",  
              "MM-DD-YYYY",  
              "DD/MM/YYYY",  
              "MM/DD/YYYY",  
            ];      
            const startDateString = moment(startDate, formats, true);
            return startDateString.format("YYYY-MM-DD");
          }
          if (!yearServiceObj[year]) {
            yearServiceArray_.push({ label: year, value: year });
            yearServiceObj = {
              ...yearServiceObj,
              [year]: {
                sales: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                balance: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
            };
          }
          yearServiceObj[year]["sales"][month - 1] += parseFloat(
            parseFloat(item_.totalAmount) - parseFloat(item_.discount)
          );
          yearServiceObj[year]["balance"][month - 1] +=
            parseFloat(item_.totalAmount) -
            parseFloat(item_.discount) -
            parseFloat(item_.amountPaid);

          return item_;
        });
      }

      if (item.consultation) {
        item.consultation.map((item_) => {
          let startDate = item_?.createdAt?.seconds
            ? item_?.createdAt?.nanoseconds / 1000000 +
              item_?.createdAt?.seconds * 1000
            : item_?.createdAt;

          startDate = moment.utc(startDate);

          let month = Number(startDate.format("MM"));
          let year = Number(startDate.format("YYYY"));

          if (!yearConsultationObj[year]) {
            yearConsultationArray_.push({ label: year, value: year });
            yearConsultationObj = {
              ...yearConsultationObj,
              [year]: {
                sales: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                balance: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
            };
          }
          yearConsultationObj[year]["sales"][month - 1] += parseFloat(
            parseFloat(item_.totalAmount) - parseFloat(item_.discount)
          );
          yearConsultationObj[year]["balance"][month - 1] +=
            parseFloat(item_.totalAmount) -
            parseFloat(item_.discount) -
            parseFloat(item_.amountPaid);

          return item_;
        });
      }

      if (item.products) {
        item.products.map((item_) => {
          purchasedAt = item_.purchasedAt;
          purchasedAt = purchasedAt?.seconds
            ? purchasedAt?.nanoseconds / 1000000 + purchasedAt?.seconds * 1000
            : purchasedAt;
          purchasedAt = moment.utc(purchasedAt);

          let year = purchasedAt.year();
          let month = purchasedAt.month();

          if (!yearProductObj[year]) {
            yearProductArray_.push({ label: year, value: year });
            yearProductObj = {
              ...yearProductObj,
              [year]: {
                sales: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                balance: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
            };
          }

          yearProductObj[year]["sales"][month] += parseFloat(
            calculateProductFinalPrice(
              item_.quantity,
              item_.sellingPriceWOT,
              item_.discount,
              item_.gst
            )
          );
          yearProductObj[year]["balance"][month] +=
            parseInt(item_.quantity) *
              (
                parseFloat(item_?.sellingPriceWOT) -
                parseFloat(item_?.discount ?? 0) +
                parseFloat(
                  parseFloat(item_?.sellingPriceWOT) *
                    (parseFloat(item_?.gst ?? 0) / 100)
                )
              ).toFixed(2) -
            parseFloat(item_.amountPaid ?? 0);

          return item_;
        });
      }
      return item;
    });

    setYearWiseServiceChartData(yearServiceObj);
    setYearWiseProductChartData(yearProductObj);
    setYearWiseConsultationChartData(yearConsultationObj);
    setServiceYearArray(yearServiceArray_);
    setProductYearArray(yearProductArray_);
    setConsultationYearArray(yearConsultationArray_);

    setServiceChartData({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Total sales amount",
          color: "info",
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ].map(
            (item, index) =>
              yearServiceObj?.[today.getFullYear()]?.["sales"][index] ?? 0
          ),
        },
        {
          label: "Balance",
          color: "dark",
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ].map(
            (item, index) =>
              yearServiceObj[today.getFullYear()]?.["balance"][index] ?? 0
          ),
        },
      ],
    });

    setConsultationChartData({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Total sales amount",
          color: "info",
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ].map(
            (item, index) =>
              yearConsultationObj?.[today.getFullYear()]?.["sales"][index] ?? 0
          ),
        },
        {
          label: "Balance",
          color: "dark",
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ].map(
            (item, index) =>
              yearConsultationObj[today.getFullYear()]?.["balance"][index] ?? 0
          ),
        },
      ],
    });

    setProductChartData({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Total sales amount",
          color: "info",
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ].map(
            (item, index) =>
              yearProductObj?.[today.getFullYear()]?.["sales"][index] ?? 0
          ),
        },
        {
          label: "Balance",
          color: "dark",
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ].map(
            (item, index) =>
              yearProductObj?.[today.getFullYear()]?.["balance"][index] ?? 0
          ),
        },
      ],
    });
  }, [clients, expensesChartData]);

  useEffect(() => {
    setEarExpBalChartData({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Incomings",
          color: "info",
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ].map((item, index) => incomingsYearwiseArray[index] ?? 0),
        },
        {
          label: "balance",
          color: "dark",
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ].map((item, index) => balSerProConYearwiseArray[index] ?? 0),
        },
        {
          label: "Expenses",
          color: "error",
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ].map(
            (item, index) =>
              yearWiseExpensesChartData?.[chartYear?.incExpBal]?.[
                "totalExpenses"
              ][index] ?? 0
          ),
        },
      ],
    });
  }, [balSerProConYearwiseArray]);

  //for setting chart according to year
  function getYear() {
    let today = new Date();
    return today.getFullYear();
  }

  const [chartYear, setChartYear] = useState({
    service: getYear(),
    product: getYear(),
    consultation: getYear(),
    expense: getYear(),
    incExpBal: getYear(),
  });

  useEffect(() => {
    if (chartYear["service"]) {
      setServiceChartData({
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Total sales amount",
            color: "info",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ].map(
              (item, index) =>
                yearWiseServiceChartData?.[chartYear["service"]]?.["sales"][
                  index
                ] ?? 0
            ),
          },
          {
            label: "Balance",
            color: "dark",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ].map(
              (item, index) =>
                yearWiseServiceChartData?.[chartYear["service"]]?.["balance"][
                  index
                ] ?? 0
            ),
          },
        ],
      });
    }

    if (chartYear["consultation"]) {
      setConsultationChartData({
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Total sales amount",
            color: "info",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ].map(
              (item, index) =>
                yearWiseConsultationChartData?.[chartYear["consultation"]]?.[
                  "sales"
                ][index] ?? 0
            ),
          },
          {
            label: "Balance",
            color: "dark",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ].map(
              (item, index) =>
                yearWiseConsultationChartData?.[chartYear["consultation"]]?.[
                  "balance"
                ][index] ?? 0
            ),
          },
        ],
      });
    }

    if (chartYear["product"]) {
      setProductChartData({
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Total sales amount",
            color: "info",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ].map(
              (item, index) =>
                yearWiseProductChartData?.[chartYear["product"]]?.["sales"][
                  index
                ] ?? 0
            ),
          },
          {
            label: "Balance",
            color: "dark",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ].map(
              (item, index) =>
                yearWiseProductChartData?.[chartYear["product"]]?.["balance"][
                  index
                ] ?? 0
            ),
          },
        ],
      });
    }
  }, [
    chartYear,
    yearWiseProductChartData,
    yearWiseServiceChartData,
    yearWiseConsultationChartData,
  ]);

  useEffect(() => {
    dashboardStatHandler();
  }, [clients, dashboardStatHandler]);

  const [client, setClient] = useState();
  const [occasion, setOccasion] = useState();
  const [newWishModal, setNewWishModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [servicesForRenewable, setServicesForRenewable] = useState([]);
  const fetchClientDetails = useCallback(async () => {
    try {
      setLoading(true);
      const fetchedRenewableServices = await fetchRenewables({ uid });
      setServicesForRenewable(fetchedRenewableServices);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  }, [uid]);

  useEffect(() => {
    fetchClientDetails();
  }, [fetchClientDetails]);

  // expense module
  const [expenseArray, setExpenseArray] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true);
        const expenseData = await getExpenses(uid);
        setExpenseArray(expenseData);
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setLoader(false);
      }
    }
    fetchData();
  }, [uid]);

  useEffect(() => {
    setIncomingYearArray([...productYearArray]);
  }, [productYearArray]);

  useEffect(() => {
    // This block of code will run when either `yearWiseConsultationChartData` or `chartYear` changes.

    // Check if both `yearWiseConsultationChartData` and `chartYear` are truthy.
    if (yearWiseConsultationChartData && chartYear) {
      // Check if the 'expense' property exists in chartYear and if it has a corresponding entry in yearWiseConsultationChartData.
      const consultationData =
        yearWiseConsultationChartData[chartYear["incExpBal"]];
      const productData = yearWiseProductChartData[chartYear["incExpBal"]];
      const serviceData = yearWiseServiceChartData[chartYear["incExpBal"]];

      // Log information to the console. Access the 'sales' property.
      const consultationTotalSales = consultationData
        ? calculateTotalForSales(
            yearWiseConsultationChartData?.[chartYear["incExpBal"]]?.["sales"]
          )
        : 0;
      const productTotalSales = productData
        ? calculateTotalForSales(
            yearWiseProductChartData?.[chartYear["incExpBal"]]?.["sales"]
          )
        : 0;
      const serviceTotalSales = serviceData
        ? calculateTotalForSales(
            yearWiseServiceChartData?.[chartYear["incExpBal"]]?.["sales"]
          )
        : 0;

      setTotalIncomings(
        consultationTotalSales + productTotalSales + serviceTotalSales
      );
    }
  }, [
    yearWiseConsultationChartData,
    yearWiseProductChartData,
    yearWiseServiceChartData,
    chartYear,
  ]);

  const generateChartData = (data, chartYear, isIncExpBal) => {
    const labelArray = Array.from({ length: 12 }, (_, i) =>
      moment().month(i).format("MMM")
    );
    
    return {
      labels: labelArray,
      datasets: [
        {
          label: "Expenses",
          color: "error",
          data: labelArray.map(
            (item, index) =>
              data?.[chartYear["expense"]]?.["totalExpenses"][index] ?? 0
          ),
        },
      ],
    };
  };

  const expensesStatHandler = useCallback(() => {
    const yearExpensesObj = {};
    const yearExpensesArray_ = [];

    expenseArray.forEach((expense) => {
      const expenseDate = moment.utc(expense.dateOfExpense);
      const month = expenseDate.month();
      const year = expenseDate.year();

      if (!yearExpensesObj[year]) {
        yearExpensesArray_.push({ label: year, value: year });
        yearExpensesObj[year] = {
          totalExpenses: Array(12).fill(0),
          tax: Array(12).fill(0),
        };
      }

      const totalExpense = calculateTotal(expense.expenseAmount, expense.tax);
      yearExpensesObj[year].totalExpenses[month] += totalExpense;
    });

    setYearWiseExpensesChartData(yearExpensesObj);
    setExpensesYearArray(yearExpensesArray_);

    setExpensesChartData({
      labels: Array.from({ length: 12 }, (_, i) =>
        moment().month(i).format("MMM")
      ),
      datasets: [
        {
          label: "Total Expenses",
          color: "info",
          data: yearExpensesObj[getYear()]?.totalExpenses || [],
        },
      ],
    });
  }, [expenseArray]);

  useEffect(() => {
    expensesStatHandler();
  }, [expensesStatHandler]);

  useEffect(() => {
    if (chartYear["expense"]) {
      setExpensesChartData(
        generateChartData(yearWiseExpensesChartData, chartYear)
      );
    }

    if (yearWiseExpensesChartData) {
      const totalExpenses =
        yearWiseExpensesChartData?.[chartYear?.incExpBal]?.["totalExpenses"];

      if (totalExpenses) {
        const calculateTotalExpense = calculateTotalForSales(totalExpenses);

        setTotalExpense(calculateTotalExpense);
      } else {
        setTotalExpense(0);
      }
    }
  }, [chartYear, yearWiseExpensesChartData]);

  useEffect(() => {
    let mockArray = Array.from({ length: 12 }, () => 0);

    const extractBalanceFromYearwise = (
      arr1,
      arr2,
      arr3,
      selectedYearFrom,
      financeType,
      setFunction
    ) => {
      const array1 = arr1?.[chartYear[selectedYearFrom]]?.[financeType]
        ? arr1?.[chartYear[selectedYearFrom]]?.[financeType]
        : mockArray;
      const array2 = arr2?.[chartYear[selectedYearFrom]]?.[financeType]
        ? arr2?.[chartYear[selectedYearFrom]]?.[financeType]
        : mockArray;
      const array3 = arr3?.[chartYear[selectedYearFrom]]?.[financeType]
        ? arr3?.[chartYear[selectedYearFrom]]?.[financeType]
        : mockArray;
      let serviceProductConsultYearwiseArray = [];

      if (array1 && array2 && array3) {
        mockArray?.map((element, index) =>
          serviceProductConsultYearwiseArray?.push(
            array1[index] + array2[index] + array3[index]
          )
        );
      }
      setFunction(serviceProductConsultYearwiseArray);
    };
    if (
      yearWiseServiceChartData &&
      yearWiseConsultationChartData &&
      yearWiseProductChartData
    ) {
      extractBalanceFromYearwise(
        yearWiseServiceChartData,
        yearWiseConsultationChartData,
        yearWiseProductChartData,
        "incExpBal",
        "sales",
        setIncomingsYearwiseArray
      );
      extractBalanceFromYearwise(
        yearWiseServiceChartData,
        yearWiseConsultationChartData,
        yearWiseProductChartData,
        "incExpBal",
        "balance",
        setBalSerProConYearwiseArray
      );
    }
  }, [
    yearWiseServiceChartData,
    yearWiseConsultationChartData,
    yearWiseProductChartData,
    chartYear,
  ]);

  useEffect(() => {
    setTotalBalSerProConYearwiseArray(
      calculateTotalForSales(balSerProConYearwiseArray)
    );
  }, [balSerProConYearwiseArray]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            {MiniCardData.map((item, index) => (
              <Grid item xs={12} sm={6} xl={3} key={index}>
                <MiniStatisticsCard
                  title={{ text: item?.title }}
                  count={loader ? "" : item?.count}
                  onClickHandler={
                    item.onTileClickHandler
                      ? item.onTileClickHandler
                      : () => null
                  }
                  percentage={
                    loader
                      ? {}
                      : {
                          color: item?.percent?.color,
                          text: item?.percent?.text,
                        }
                  }
                  icon={{
                    color: item?.icon?.color,
                    component: item?.icon?.component,
                    para: item?.icon?.para,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </SuiBox>

        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={4}>
              <AnniversariesAndBirthdays
                title={"Today's Birthday/Anniversary"}
                tooltip={"List of your today's birthdays/Anniversaries"}
                uid={uid}
                wishHandler={(client_, occasionName) => {
                  setClient(client_);
                  setNewWishModal(true);
                  setOccasion(occasionName);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={4}>
              <Appointments
                title={"Today's Appointments"}
                tooltip={"List of your today's appointments"}
                uid={uid}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={4}>
              <Leads
                title={"Today's Lead Follow-up"}
                tooltip={"List of your today's lead follow-up"}
                uid={uid}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={4}>
              <Renewables
                title={"Upcomming Service Renewables"}
                tooltip={"List of your client's renewable services"}
                loader={loading}
                renewables={servicesForRenewable}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={4}>
              <Balance
                title={"Balance Receivable"}
                tooltip={"List of your balance receivable"}
                uid={uid}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={4}>
              <Dietchart
                title={"Diet Chart Reminder"}
                tooltip={"List of your diet chart to create"}
                uid={uid}
              />
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <DashboardChart
              loader={loader}
              title={"Service Sales Overview"}
              text={"Service sales overview over the months"}
              data={serviceChartData}
              tag="service"
              setChartYear={setChartYear}
              yearArray={serviceYearArray}
              chartYear={chartYear}
            />

            <DashboardChart
              loader={loader}
              title={"Product Sales Overview"}
              text={"Product sales overview over the months"}
              data={productChartData}
              tag="product"
              setChartYear={setChartYear}
              yearArray={productYearArray}
              chartYear={chartYear}
            />

            <DashboardChart
              loader={loader}
              title={"Consultation Overview"}
              text={"Consultation fee overview over the months"}
              data={consultationChartData}
              tag="consultation"
              setChartYear={setChartYear}
              yearArray={consultationYearArray}
              chartYear={chartYear}
            />

            <DashboardChart
              loader={loader}
              title={"Expense Overview"}
              text={"Expense overview over the months"}
              data={expensesChartData}
              tag="expense"
              setChartYear={setChartYear}
              yearArray={expensesYearArray}
              chartYear={chartYear}
            />

            <EarningExpenseTracker
              loader={loader}
              title={"Incomings, Expense & Balance"}
              setChartYear={setChartYear}
              chartYear={chartYear}
              yearArray={incomingYearArray}
              data={earExpBalChartData}
              totalExpense={totalExpense}
              balance={totalBalSerProConYearwiseArray}
              totalIncomings={totalIncomings}
              tag="incExpBal"
            />
          </Grid>
        </SuiBox>
      </SuiBox>

      <WishModal
        open={newWishModal}
        setOpenModal={setNewWishModal}
        occasion={occasion}
        client={client}
        handleCloseModal={() => {
          setNewWishModal(false);
        }}
      />
    </DashboardLayout>
  );
}
export default Dashboard;
