import React, { useState, useEffect, useMemo } from "react";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Custom styles for the Modal
import { toast } from "react-toastify";
import Createable from "containers/Select";
import Grid from "@mui/material/Grid";
import MultipleTags from "containers/Tags";
import { timestampConversion } from "utils/common";
import moment from "moment";
import { submitDataHandler } from "../utils";
import CustomButton from "containers/CustomButton";
import { EditBiochemical_Dietry } from "services/clientdetails";
import { AddBiochemical_Dietry } from "services/clientdetails";
import { obesityOptions } from "constant";
import { heatiestMealOptions } from "constant";
import { fastFoodOptions } from "constant";
import { foodsYouEnjoyOptions } from "constant";
import { rashesOptions } from "constant";
import { updateRow } from "utils/common";
import { v4 as uuidv4 } from "uuid";
import { smokeOption } from "constant";
import { alcoholOption } from "constant";
import { InputOption } from "utils/common";
import { updateFirstLetter } from "utils/helper";

function BiochemicalModal({
  open,
  id,
  selectedItem,
  setSelectedItem,
  setSelectedClient,
  handleCloseModal,
  persona,
  setMainData,
  mode,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      doctorName: null,
      bp: null,
      bloodSugar: null,

      pp: null,
      hbaic: null,
      cholestrol: null,

      hdl: null,
      vldl: null,
      ldl: null,

      sgot: null,
      sgpt: null,
      hb: null,

      d3: null,
      vitaminb12: null,
      vitamin: null,

      calcium: null,
      uricAcid: null,

      abg: null,
      t3: null,
      t4: null,
      crp: null,
      potassium: null,
      bloodUrea: null,
      bloodUreaNitrogen: null,
      transferrin: null,
      triglycerides: null,
      sodium: null,
      bilirubin: null,
      rbc: null,
      wbc: null,
      creatinine: null,
      lastPeriodDate: null,
      // Biochemical
      ongoingMedication: null,
      operated: null,
      durationMediction: null,
      allergicFrom: null,
      sufferingFrom: null,
      anyOtherCondition: null,
      rashesOnSkin: null,
      obesityInFamily: null,
      smoke: null,
      smokeAmount: null,
      alcohol: null,
      alcoholAmount: null,
      foodYouLike: null,
      foodYouDislike: null,
      waterIntake: null,
      sleepHours: null,
      habitOfEating: null,
      allergicFoods: null,
      heartiestMeal: null,
      skippedMeal: null,
      fastFoodConsumption: null,
      softDrinks: null,
      isFastingObserve: null,
      isLooseWeightTried: null,
      looseWeightProgramme: null,
      weightLost: null,
      weightTimePeriod: null,

      timestamp: new Date(),
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      doctorName: false,
      bp: false,
      bloodSugar: false,

      pp: false,
      hbaic: false,
      cholestrol: false,

      hdl: false,
      vldl: false,
      ldl: false,

      sgot: false,
      sgpt: false,
      hb: false,

      d3: false,
      vitaminb12: false,
      vitamin: false,

      calcium: false,
      uricAcid: false,

      abg: false,
      t3: false,
      t4: false,
      crp: false,
      potassium: false,
      bloodUrea: false,
      bloodUreaNitrogen: false,
      transferrin: false,
      triglycerides: false,
      sodium: false,
      bilirubin: false,
      rbc: false,
      wbc: false,
      creatinine: false,

      lastPeriodDate: false,
      // Biochemical
      ongoingMedication: false,
      operated: false,
      durationMediction: false,
      allergicFrom: false,
      sufferingFrom: false,
      anyOtherCondition: false,
      rashesOnSkin: false,
      obesityInFamily: false,
      smoke: false,
      smokeAmount: false,
      alcohol: false,
      alcoholAmount: false,
      foodYouLike: false,
      foodYouDislike: false,
      waterIntake: false,
      sleepHours: false,
      habitOfEating: false,
      allergicFoods: false,
      timestamp: false,
    };
  }, []);

  const [formdata, setFormdata] = useState(defaultHandler);
  const [errorMessage, setErrorMessage] = useState(errorDefault);
  const [loader, setLoader] = useState(false);
  const [foodsYouEnjoyDefaultValues, setFoodsYouEnjoy] = useState([{}]);

  useEffect(() => {
    if (selectedItem) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        rashesOnSkin: {
          label: selectedItem.rashesOnSkin,
          value: selectedItem.rashesOnSkin,
        },
        obesityInFamily: {
          label: selectedItem.obesityInFamily,
          value: selectedItem.obesityInFamily,
        },
        smoke: {
          label: selectedItem.smoke,
          value: selectedItem.smoke,
        },
        alcohol: {
          label: selectedItem.alcohol,
          value: selectedItem.alcohol,
        },
        heartiestMeal: {
          label: selectedItem.heartiestMeal,
          value: selectedItem.heartiestMeal,
        },
        skippedMeal: {
          label: selectedItem.skippedMeal,
          value: selectedItem.skippedMeal,
        },
        fastFoodConsumption: {
          label: selectedItem.fastFoodConsumption,
          value: selectedItem.fastFoodConsumption,
        },
        softDrinks: {
          label: selectedItem.softDrinks,
          value: selectedItem.softDrinks,
        },
        isFastingObserve: {
          label: selectedItem.isFastingObserve,
          value: selectedItem.isFastingObserve,
        },
        isLooseWeightTried: {
          label: selectedItem.isLooseWeightTried?.label ?? "",
          value: selectedItem.isLooseWeightTried?.value ?? "",
        },
        foodsYouEnjoy: [...(selectedItem?.foodsYouEnjoy || [])],
        timestamp: selectedItem?.timestamp
          ? selectedItem?.timestamp?.seconds
            ? timestampConversion(selectedItem?.timestamp)._d
            : selectedItem?.timestamp
          : new Date(),
      });

      const foodsYouEnjoy =
        selectedItem?.foodsYouEnjoy?.map((item) => {
          return {
            value: item,
            label: item ? updateFirstLetter(item) : "",
          };
        }) ?? [];

      setFoodsYouEnjoy(foodsYouEnjoy);
    }
  }, [selectedItem, defaultHandler]);


  useEffect(() => {
    if (!open) {
      setFormdata(defaultHandler);
      handleCloseModal();
    }
  }, [open, defaultHandler, handleCloseModal]);

  //for submitting data
  const submitHandler = async (e) => {
    if (formdata.isFastingObserve?.value === "Yes") {
      if (
        formdata.observeFasting === "" ||
        formdata.observeFasting === undefined
      ) {
        toast.error("Please fill How often observe fasting? field!");
        return;
      }
    }
    if (formdata?.isLooseWeightTried?.value === "Yes") {
      if (!formdata.looseWeightProgramme) {
        toast.error("Please fill what type of programme?");
        return;
      } else if (!formdata.weightLost) {
        toast.error("Please fill How much weight lost?");
        return;
      }
    } else if (
      formdata?.weightTimePeriod === "" ||
      formdata?.weightTimePeriod === undefined
    ) {
      toast.error("Please fill How much time period?");
      return;
    }

    if (!formdata.timestamp) {
      toast.error("Please fill date/time field");
      setErrorMessage({
        ...errorMessage,
        timestamp: true,
      });
      return;
    }

    if (persona === "admin") {
      await submitDataHandler({
        setLoader,
        selectedItem,
        editSubmitHandler: async () =>
          await EditBiochemical_Dietry({ formdata, id }),
        addSubmitHandler: async () =>
          await AddBiochemical_Dietry({ formdata, id }),
        setSelectedClient,
        key: "biochemical",
        setSelectedItem,
        handleCloseModal,
        setFormdata,
        setErrorMessage,
        defaultHandler,
        errorDefault,
      });
    } else if (persona === "client") {
      const spreadData = {
        ...formdata,
        rashesOnSkin: formdata.rashesOnSkin?.value ?? "",
        obesityInFamily: formdata.obesityInFamily?.value ?? "",
        smoke: formdata.smoke?.value ?? "",
        alcohol: formdata.alcohol?.value ?? "",
        heartiestMeal: formdata.heartiestMeal?.value ?? "",
        skippedMeal: formdata.skippedMeal?.value ?? "",
        fastFoodConsumption: formdata.fastFoodConsumption?.value ?? "",
        softDrinks: formdata.softDrinks?.value ?? "",
        isFastingObserve: formdata.isFastingObserve?.value ?? "",
        isLooseWeightTried: formdata.isFastingObserve?.value ?? "",
        foodsYouEnjoy: formdata.foodsYouEnjoy ?? [],

        observeFasting:
          formdata?.isFastingObserve?.value === "Yes"
            ? formdata.observeFasting
            : "",
        looseWeightProgramme:
          formdata?.isLooseWeightTried?.value === "Yes"
            ? formdata.looseWeightProgramme
            : "",
      };

      const docId = uuidv4();

      setMainData((data) => {
        if (selectedItem) {
          const updatedData = updateRow(selectedItem.id, spreadData, data);
          data = updatedData;
        } else {
          data = [{ id: docId, ...spreadData, createdAt: new Date() }, ...data];
        }
        return data;
      });
      handleCloseModal();
    }
  };

  return (
    <SuiBox px={3}>
      <SuiBox>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Doctor Name
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="doctorName"
                readOnly={mode === "view"}
                error={errorMessage?.doctorName}
                value={formdata.doctorName ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                BP
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="bp"
                readOnly={mode === "view"}
                error={errorMessage?.bp}
                value={formdata.bp ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Blood Sugar
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="bloodSugar"
                readOnly={mode === "view"}
                error={errorMessage?.bloodSugar}
                value={formdata.bloodSugar ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                PP
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="pp"
                readOnly={mode === "view"}
                error={errorMessage?.pp}
                value={formdata.pp ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                HBAIC
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="hbaic"
                readOnly={mode === "view"}
                error={errorMessage?.hbaic}
                value={formdata.hbaic ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Cholestrol
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="cholestrol"
                readOnly={mode === "view"}
                error={errorMessage?.cholestrol}
                value={formdata.cholestrol ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                HDL
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="hdl"
                readOnly={mode === "view"}
                error={errorMessage?.hdl}
                value={formdata.hdl ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                LDL
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="ldl"
                readOnly={mode === "view"}
                error={errorMessage?.ldl}
                value={formdata.ldl ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                VLDL
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="vldl"
                readOnly={mode === "view"}
                error={errorMessage?.vldl}
                value={formdata.vldl ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                SGOT
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="sgot"
                readOnly={mode === "view"}
                error={errorMessage?.sgot}
                value={formdata.sgot ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                SGPT
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="sgpt"
                readOnly={mode === "view"}
                error={errorMessage?.sgpt}
                value={formdata.sgpt ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                HB
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="hb"
                readOnly={mode === "view"}
                error={errorMessage?.hb}
                value={formdata.hb ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Vitamin
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="vitamin"
                readOnly={mode === "view"}
                error={errorMessage?.vitamin}
                value={formdata.vitamin ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                D3
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={mode === "view"}
                name="d3"
                error={errorMessage?.d3}
                value={formdata.d3 ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Vitamin B12
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="vitaminb12"
                readOnly={mode === "view"}
                error={errorMessage?.vitaminb12}
                value={formdata.vitaminb12 ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Calcium
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="calcium"
                readOnly={mode === "view"}
                error={errorMessage?.calcium}
                value={formdata.calcium ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Uric Acid
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="uricAcid"
                readOnly={mode === "view"}
                error={errorMessage?.uricAcid}
                value={formdata.uricAcid ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                ABG
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="abg"
                readOnly={mode === "view"}
                error={errorMessage?.abg}
                value={formdata.abg ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                T3
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="t3"
                readOnly={mode === "view"}
                error={errorMessage?.t3}
                value={formdata.t3 ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                T4
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="t4"
                readOnly={mode === "view"}
                error={errorMessage?.t4}
                value={formdata.t4 ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                CRP
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="crp"
                readOnly={mode === "view"}
                error={errorMessage?.crp}
                value={formdata.crp ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Potassium
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="potassium"
                readOnly={mode === "view"}
                error={errorMessage?.potassium}
                value={formdata.potassium ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Blood Urea
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="bloodUrea"
                readOnly={mode === "view"}
                error={errorMessage?.bloodUrea}
                value={formdata.bloodUrea ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Blood Urea Nitrogen
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="bloodUreaNitrogen"
                readOnly={mode === "view"}
                error={errorMessage?.bloodUreaNitrogen}
                value={formdata.bloodUreaNitrogen ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Transferrin
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="transferrin"
                readOnly={mode === "view"}
                error={errorMessage?.transferrin}
                value={formdata.transferrin ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Triglycerides
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="triglycerides"
                readOnly={mode === "view"}
                error={errorMessage?.triglycerides}
                value={formdata.triglycerides ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Sodium
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="sodium"
                readOnly={mode === "view"}
                error={errorMessage?.sodium}
                value={formdata.sodium ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Bilirubin
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="bilirubin"
                readOnly={mode === "view"}
                error={errorMessage?.bilirubin}
                value={formdata.bilirubin ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                RBC
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="rbc"
                readOnly={mode === "view"}
                error={errorMessage?.rbc}
                value={formdata.rbc ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                WBC
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="wbc"
                readOnly={mode === "view"}
                error={errorMessage?.wbc}
                value={formdata.wbc ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Creatinine
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="creatinine"
                readOnly={mode === "view"}
                error={errorMessage?.creatinine}
                value={formdata.creatinine ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={6} md={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={0.5} variant="h6">
                Last Period Date
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="date"
                readOnly={mode === "view"}
                name="lastPeriodDate"
                error={errorMessage?.lastPeriodDate}
                value={formdata.lastPeriodDate ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

      {/* {mode !== "view" && (
        <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
          <SuiBox mb={2} width="32%">
            <CustomButton
              color="dark"
              variant="gradient"
              onClick={submitHandler}
              fullWidth
              width={180}
              disabled={loader || !open}
              title={selectedItem ? "Edit Data" : "Add Data"}
              loader={loader}
            />
          </SuiBox>
        </SuiBox>
      )} */}
      <SuiBox mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Ongoing Medication
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="ongoingMedication"
                readOnly={mode === "view"}
                placeholder="ongoing medication ..."
                rows={6}
                multiline
                error={errorMessage?.ongoingMedication}
                value={formdata.ongoingMedication ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} md={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Operated?
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="operated"
                readOnly={mode === "view"}
                placeholder="operated ..."
                rows={6}
                multiline
                error={errorMessage?.operated}
                value={formdata.operated ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} md={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Duration & Medication
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="durationMediction"
                readOnly={mode === "view"}
                placeholder="duration & medication ..."
                rows={6}
                multiline
                error={errorMessage?.durationMediction}
                value={formdata.durationMediction ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Allergic From
              </SuiTypography>
              <MultipleTags
                disabled={mode === "view"}
                placeholder="allergic from ..."
                valList={formdata.allergicFrom}
                freeTextHanlder={(val) => {
                  setFormdata({
                    ...formdata,
                    allergicFrom: val,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} md={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Suffering From
              </SuiTypography>
              <MultipleTags
                disabled={mode === "view"}
                placeholder="suffering from ..."
                valList={formdata.sufferingFrom ?? ""}
                freeTextHanlder={(val) => {
                  setFormdata({
                    ...formdata,
                    sufferingFrom: val,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} md={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Any Other Condition?
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={mode === "view"}
                name="anyOtherCondition"
                placeholder="any other condition? ..."
                rows={5.2}
                multiline
                error={errorMessage?.anyOtherCondition}
                value={formdata.anyOtherCondition ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Do You Smoke?
              </SuiTypography>
              <Createable
                disabled={mode === "view"}
                options={smokeOption}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    smoke: e,
                  });
                }}
                value={formdata.smoke}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                If Yes, How ,many cigarettes in a day?
              </SuiTypography>
              <SuiInput
                type="text"
                readOnly={mode === "view"}
                name="smokeAmount"
                placeholder="amount of cigarette ..."
                disabled={
                  formdata.smoke?.value === "No" ||
                  mode === "view" ||
                  !formdata.smoke
                }
                error={errorMessage?.smokeAmount}
                value={formdata.smokeAmount ?? ""}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    smokeAmount: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Do You Consume Alcohol?
              </SuiTypography>
              <Createable
                disabled={mode === "view"}
                options={alcoholOption}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    alcohol: e,
                  });
                }}
                value={formdata.alcohol}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                How Much?
              </SuiTypography>
              <SuiInput
                type="text"
                readOnly={mode === "view"}
                name="alcoholAmount"
                placeholder="amount of alcohol ..."
                disabled={
                  formdata.alcohol?.value === "No" ||
                  mode === "view" ||
                  !formdata.alcohol
                }
                error={errorMessage?.alcoholAmount}
                value={formdata.alcoholAmount ?? ""}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    alcoholAmount: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Food You Like
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={mode === "view"}
                name="foodYouLike"
                placeholder="food you like ..."
                error={errorMessage?.foodYouLike}
                value={formdata.foodYouLike ?? ""}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    foodYouLike: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Food You Dislike
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={mode === "view"}
                name="foodYouDislike"
                placeholder="food you dislike ..."
                error={errorMessage?.foodYouDislike}
                value={formdata.foodYouDislike ?? ""}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    foodYouDislike: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Heartiest Meal of the Day
              </SuiTypography>
              <Createable
                disabled={mode === "view"}
                options={heatiestMealOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    heartiestMeal: e,
                  });
                }}
                value={formdata.heartiestMeal}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Skipped Meal of the Day
              </SuiTypography>
              <Createable
                disabled={mode === "view"}
                options={heatiestMealOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    skippedMeal: e,
                  });
                }}
                value={formdata.skippedMeal}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Foods You Enjoy
              </SuiTypography>
              {foodsYouEnjoyDefaultValues?.[0]?.value && (
                <Createable
                  components={{
                    Option: InputOption,
                  }}
                  defaultValue={foodsYouEnjoyDefaultValues}
                  disabled={mode === "view"}
                  options={foodsYouEnjoyOptions ?? []}
                  style={{
                    borderRadius: "0.5rem",
                    fontSize: 14,
                    zIndex: 999,
                  }}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Foods you prefer"
                  onChange={(options) => {
                    if (Array.isArray(options)) {
                      setFormdata({
                        ...formdata,
                        foodsYouEnjoy: options.map((opt) => opt.value),
                      });
                    }
                  }}
                />
              )}

              {!foodsYouEnjoyDefaultValues?.[0]?.value && (
                <Createable
                  components={{
                    Option: InputOption,
                  }}
                  disabled={mode === "view"}
                  options={foodsYouEnjoyOptions ?? []}
                  style={{
                    borderRadius: "0.5rem",
                    fontSize: 14,
                    zIndex: 999,
                  }}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Foods you prefer"
                  onChange={(options) => {
                    if (Array.isArray(options)) {
                      setFormdata({
                        ...formdata,
                        foodsYouEnjoy: options.map((opt) => opt.value),
                      });
                    }
                  }}
                />
              )}
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Fast Food Consumption
              </SuiTypography>
              <Createable
                disabled={mode === "view"}
                options={fastFoodOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    fastFoodConsumption: e,
                  });
                }}
                value={formdata.fastFoodConsumption}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Soft Drinks/Packed Fruit Juices Consumption
              </SuiTypography>
              <Createable
                disabled={mode === "view"}
                options={fastFoodOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    softDrinks: e,
                  });
                }}
                value={formdata.softDrinks}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Do You Observe Fasting?
              </SuiTypography>
              <Createable
                disabled={mode === "view"}
                options={rashesOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    isFastingObserve: e,
                  });
                }}
                value={formdata.isFastingObserve}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                How often observe fasting?
              </SuiTypography>
              <SuiInput
                disabled={
                  mode === "view" || formdata?.isFastingObserve?.value !== "Yes"
                }
                type="text"
                readOnly={mode === "view"}
                name="observeFasting"
                placeholder="How often do you observe fasting?"
                error={errorMessage?.observeFasting}
                value={formdata.observeFasting ?? ""}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    observeFasting: formdata.isFastingObserve
                      ? e.target.value
                      : "",
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Earlier tried to lose weight?
              </SuiTypography>
              <Createable
                disabled={mode === "view"}
                options={rashesOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    isLooseWeightTried: e,
                  });
                }}
                value={formdata.isLooseWeightTried}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                What type of programme?
              </SuiTypography>
              <SuiInput
                disabled={
                  mode === "view" ||
                  formdata?.isLooseWeightTried?.value !== "Yes"
                }
                type="text"
                readOnly={mode === "view"}
                name="looseWeightProgramme"
                placeholder="Programme to loose weight.."
                error={errorMessage?.looseWeightProgramme}
                value={formdata.looseWeightProgramme ?? ""}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    looseWeightProgramme: formdata.isLooseWeightTried
                      ? e.target.value
                      : "",
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                How much weight lost? (In KG)
              </SuiTypography>
              <SuiInput
                disabled={
                  mode === "view" ||
                  formdata?.isLooseWeightTried?.value !== "Yes"
                }
                type="text"
                readOnly={mode === "view"}
                name="weightLost"
                error={errorMessage?.weightLost}
                value={formdata.weightLost ?? ""}
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setFormdata({
                      ...formdata,
                      weightLost: formdata.isLooseWeightTried
                        ? e.target.value
                        : "",
                    });
                  }
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                How much time period? (In Months)
              </SuiTypography>
              <SuiInput
                disabled={
                  mode === "view" ||
                  formdata?.isLooseWeightTried?.value !== "Yes"
                }
                placeholder="Weight loose time period.."
                type="text"
                readOnly={mode === "view"}
                name="weightLost"
                error={errorMessage?.weightTimePeriod}
                value={formdata.weightTimePeriod ?? ""}
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setFormdata({
                      ...formdata,
                      weightTimePeriod: formdata.isLooseWeightTried
                        ? e.target.value
                        : "",
                    });
                  }
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Water Consumption in a day
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={mode === "view"}
                name="waterIntake"
                placeholder="water intake ..."
                error={errorMessage?.waterIntake}
                value={formdata.waterIntake ?? ""}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    waterIntake: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                How Many Hours Do You Sleep?
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={mode === "view"}
                name="sleepHours"
                placeholder="sleep hours ..."
                error={errorMessage?.sleepHours}
                value={formdata.sleepHours ?? ""}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    sleepHours: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Rashes of Allergies on Skin?
              </SuiTypography>
              <Createable
                disabled={mode === "view"}
                options={rashesOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    rashesOnSkin: e,
                  });
                }}
                value={formdata.rashesOnSkin}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Obesity Runs in Family?
              </SuiTypography>
              <Createable
                disabled={mode === "view"}
                options={obesityOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    obesityInFamily: e,
                  });
                }}
                value={formdata.obesityInFamily}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Habit of Eating Out
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="habitOfEating"
                readOnly={mode === "view"}
                placeholder="habit of eating ..."
                rows={6}
                multiline
                error={errorMessage?.habitOfEating}
                value={formdata.habitOfEating ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    habitOfEating: false,
                  });
                  setFormdata({
                    ...formdata,
                    habitOfEating: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Allergic to any food?
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                name="allergicFoods"
                readOnly={mode === "view"}
                placeholder="allergic foods ..."
                rows={6}
                multiline
                error={errorMessage?.allergicFoods}
                value={formdata.allergicFoods ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    allergicFoods: false,
                  });
                  setFormdata({
                    ...formdata,
                    allergicFoods: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Date/time
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="datetime-local"
                name="timestamp"
                readOnly={mode === "view"}
                placeholder=""
                error={errorMessage?.timestamp}
                value={
                  formdata.timestamp
                    ? moment(formdata.timestamp).format("YYYY-MM-DDTkk:mm")
                    : moment().format("YYYY-MM-DDTkk:mm")
                }
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

      {mode !== "view" && (
        <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
          <SuiBox mb={2} width="32%">
            <CustomButton
              color="dark"
              variant="gradient"
              onClick={submitHandler}
              fullWidth
              width={180}
              disabled={loader || !open}
              title={selectedItem ? "Edit Data" : "Add Data"}
              loader={loader}
            />
          </SuiBox>
        </SuiBox>
      )}
    </SuiBox>
  );
}

export default BiochemicalModal;
