import React, { useState, useEffect, useMemo } from "react";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import { AddMeasurement, EditMeasurement } from "services/clientdetails";
import { timestampConversion } from "utils/common";
import moment from "moment";
import CustomButton from "containers/CustomButton";
import { submitDataHandler } from "../utils";
import { updateRow } from "utils/common";
import { v4 as uuidv4 } from "uuid";
import ToggleButtons from "containers/ToggleButton";

function MeasurementModal({
  open,
  handleCloseModal,
  id,
  selectedItem,
  setSelectedItem,
  setSelectedClient,
  persona,
  setMainData,
  mode,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      neck: null,
      chest: null,
      tummy: null,
      waist: null,
      hip: null,
      thigh: null,
      arm: null,
      weight: null,
      height: null,
      timestamp: new Date(),
      consultantionNotes: null,
      clientNotes: null,
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      neck: false,
      chest: false,
      tummy: false,
      waist: false,
      hip: false,
      thigh: false,
      arm: false,
      weight: false,
      timestamp: false,
      height: false,
      consultantionNotes: false,
      clientNotes: false,
    };
  }, []);

  const [formdata, setFormdata] = useState(defaultHandler);
  const [errorMessage, setErrorMessage] = useState(errorDefault);
  const [loader, setLoader] = useState(false);
  const [measurements, setMeasurements] = useState({
    neck: { value: formdata.neck ?? "", unit: "Cm" },
    chest: { value: formdata.chest ?? "", unit: "Cm" },
    tummy: { value: formdata.tummy ?? "", unit: "Cm" },
    waist: { value: formdata.waist ?? "", unit: "Cm" },
    hip: { value: formdata.hip ?? "", unit: "Cm" },
    thigh: { value: formdata.thigh ?? "", unit: "Cm" },
    arm: { value: formdata.arm ?? "", unit: "Cm" },
    height: { value: formdata.height ?? "", unit: "Cm" },
  });

  useEffect(() => {
    let orderedMetrics = {
      neck: { value: "", unit: "Cm" },
      chest: { value: "", unit: "Cm" },
      tummy: { value: "", unit: "Cm" },
      waist: { value: "", unit: "Cm" },
      hip: { value: "", unit: "Cm" },
      thigh: { value: "", unit: "Cm" },
      arm: { value: "", unit: "Cm" },
      height: { value: "", unit: "Cm" },
    };
    if (selectedItem) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        timestamp: selectedItem?.timestamp?.seconds
          ? timestampConversion(selectedItem?.timestamp)._d
          : selectedItem?.timestamp,
      });
    }

    // if (selectedItem?.metrics && mode !== "create") {
    //   for (const [key, value] of Object.entries(selectedItem.metrics)) {
    //     orderedMetrics[key] = value;
    //   }
    //   setMeasurements(orderedMetrics);
    // } else {
    //   setMeasurements(orderedMetrics);
    // }

    const updatedMetrics =
      mode !== "create" && selectedItem?.metrics
        ? { ...orderedMetrics, ...selectedItem.metrics }
        : orderedMetrics;
    setMeasurements(orderedMetrics);
    setMeasurements(updatedMetrics);
  }, [selectedItem, defaultHandler, mode]);

  //for submitting data
  const submitHandler = async (e) => {
    if (!formdata.timestamp) {
      toast.error("Please fill date/time field");
      setErrorMessage({
        ...errorMessage,
        timestamp: true,
      });
      return;
    }

    if (!formdata.consultantionNotes) {
      toast.error("Please fill Consultation Notes field");
      setErrorMessage({
        ...errorMessage,
        consultantionNotes: true,
      });
      return;
    }

    if (!formdata.clientNotes) {
      toast.error("Please fill Client Notes field");
      setErrorMessage({
        ...errorMessage,
        clientNotes: true,
      });
      return;
    }

    if (persona === "admin") {
      await submitDataHandler({
        setLoader,
        selectedItem,
        editSubmitHandler: async () =>
          await EditMeasurement({ formdata, id, metrics: measurements }),
        addSubmitHandler: async () =>
          await AddMeasurement({ formdata, id, metrics: measurements }),
        setSelectedClient,
        key: "measurement",
        handleCloseModal,
        setSelectedItem,
        setFormdata,
        setErrorMessage,
        defaultHandler,
        errorDefault,
      });
    } else if (persona === "client") {
      const docId = uuidv4();

      setMainData((data) => {
        if (selectedItem) {
          const updatedFormData = { ...formdata, metrics: { ...measurements } };
          const updatedData = updateRow(selectedItem.id, updatedFormData, data);
          data = updatedData;
        } else {
          data = [
            {
              id: docId,
              ...formdata,
              metrics: measurements,
              createdAt: new Date(),
            },
            ...data,
          ];
        }
        console.log({ data });
        return data;
      });
      handleCloseModal();
    }
  };

  const handleUnitChange = (unit, field) => {
    setMeasurements((prev) => ({
      ...prev,
      [field]: { ...prev[field], unit },
    }));
  };

  const handleMeasurementChange = (e, field, unit) => {
    const numberRegex = /^\d+(\.\d{1,2})?$/;
    let value = e.target ? e.target.value : e;
    if (!value.match(numberRegex) && value) {
      return;
    }
    setMeasurements((prev) => ({
      ...prev,
      [field]: { value: value, unit },
    }));
    setErrorMessage({
      ...errorMessage,
      [field]: false,
    });
  };

  useEffect(() => {
    if (!open) {
      setFormdata({ ...defaultHandler });
    }
  }, [open, defaultHandler]);

  const displayStyle = {
    display: "flex",
    alignItems: "center",
    gap: 8,
    marginBottom: 10,
  };

  return (
    <SuiBox>
      <SuiBox pb={3} px={3}>
        <SuiBox mt={1}>
          <Grid container spacing={2}>
            {Object.keys(measurements).map((field, index) => (
              <Grid item xs={6} md={4} key={index}>
                <SuiBox lineHeight={1}>
                  <SuiBox style={displayStyle}>
                    <SuiTypography variant="h6">
                      {field.charAt(0).toUpperCase() + field.slice(1)} (
                      {measurements[field].unit})
                    </SuiTypography>
                    <ToggleButtons
                      options={[
                        { label: "Cm", value: "Cm" },
                        { label: "Inches", value: "Inches" },
                      ]}
                      defaultValue={"Cm"}
                      size="small"
                      handleChange={(e) =>
                        handleUnitChange(e.target.value, field)
                      }
                      disabled={mode === "view"}
                      value={measurements[field].unit}
                    />
                  </SuiBox>
                  <SuiInput
                    disabled={mode === "view"}
                    type="number"
                    name={field}
                    readOnly={mode === "view"}
                    error={errorMessage?.[field]}
                    value={measurements[field].value ?? ""}
                    onChange={(e) => {
                      handleMeasurementChange(
                        e,
                        field,
                        measurements[field].unit
                      );
                      setFormdata({
                        ...formdata,
                        [e.target.name]: parseFloat(e.target.value),
                      });
                    }}
                  />
                </SuiBox>
              </Grid>
            ))}
          </Grid>
        </SuiBox>

        <SuiBox mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Weight (kgs)
                    </SuiTypography>
                    <SuiInput
                      disabled={mode === "view"}
                      type="number"
                      name="weight"
                      readOnly={mode === "view"}
                      error={errorMessage?.weight}
                      value={formdata.weight ?? ""}
                      onChange={(e) => {
                        let numberRegex = /^[0-9][0-9]{0,3}?(\.[0-9]{1,2})?$/;
                        let val = e.target.value;
                        let match_ = val.match(numberRegex);
                        if (!match_ && val) {
                          return false;
                        }
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: parseFloat(e.target.value),
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>

                <Grid item xs={12} mt={2.5}>
                  <SuiBox lineHeight={1}>
                    <SuiTypography mb={1} variant="h6">
                      Date/time
                    </SuiTypography>
                    <SuiInput
                      disabled={mode === "view"}
                      type="datetime-local"
                      name="timestamp"
                      readOnly={mode === "view"}
                      placeholder=""
                      error={errorMessage?.timestamp}
                      style={{ paddingRight: "1px" }}
                      value={
                        formdata.timestamp
                          ? moment(formdata.timestamp).format(
                              "YYYY-MM-DDTkk:mm"
                            )
                          : moment().format("YYYY-MM-DDTkk:mm")
                      }
                      onChange={(e) => {
                        setErrorMessage({
                          ...errorMessage,
                          [e.target.name]: false,
                        });
                        setFormdata({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Consultation Notes
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="consultantionNotes"
                  placeholder="Some notes about the person."
                  rows={6}
                  multiline
                  readOnly={mode === "view"}
                  error={errorMessage?.consultantionNotes}
                  value={formdata.consultantionNotes ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} md={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Client Notes
                </SuiTypography>
                <SuiInput
                  disabled={mode === "view"}
                  type="text"
                  name="clientNotes"
                  readOnly={mode === "view"}
                  placeholder="Some notes about the person."
                  rows={6}
                  multiline
                  error={errorMessage?.clientNotes}
                  value={formdata.clientNotes ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        {mode !== "view" && (
          <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
            <SuiBox mb={2} width="32%">
              <CustomButton
                color="dark"
                variant="gradient"
                onClick={submitHandler}
                fullWidth
                width={180}
                disabled={loader || !open}
                title={selectedItem ? "Edit Data" : "Add Data"}
                loader={loader}
              />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </SuiBox>
  );
}

export default MeasurementModal;
