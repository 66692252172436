import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AttendanceHistoryRow } from "./rows";
import SuiBox from "components/SuiBox";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";

import { TailSpin } from "react-loader-spinner";
import Table from "examples/Tables/Table";
import NoData from "containers/DataNotAvailBox";
import { useDispatch, useSelector } from "react-redux";
import { editEmployeeAttendance } from "store/features/employee";
import SuiTypography from "components/SuiTypography";
import { Icon } from "@mui/material";
import DatePicker from "react-datepicker";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import Createable from "containers/Select";
import { addBackDateAttendance } from "store/features/employee";
import { userSelector } from "store/features/authSlice";

const AttendanceHistoryTable = ({ selectedEmployee, loader, id }) => {
  useEffect(() => {
    selectedEmployee?.attendance?.forEach((item) => {
      const parts = item.date.split("/");
      item.date = new Date(`${parts[1]}/${parts[0]}/${parts[2]}`);
    });

    // Sort the array by date
    selectedEmployee?.attendance?.sort((a, b) => b.date - a.date);

    // Convert Date objects back to string format if needed
    selectedEmployee?.attendance?.forEach((item) => {
      const day = item.date.getDate().toString().padStart(2, "0");
      const month = (item.date.getMonth() + 1).toString().padStart(2, "0");
      const year = item.date.getFullYear();
      item.date = `${day}/${month}/${year}`;
    });
  });

  const dispatch = useDispatch();

  const [date, setDate] = useState(null);
  const [selectedToggle, setSelectedToggle] = useState(null);
  const [attendanceHistoryArray, setAttendanceHistoryArray] = useState([]);
  const [rows, setRows] = useState([]);
  const [roleOptions] = useState([
    {
      value: "all",
      label: "All",
    },
    {
      value: "part",
      label: "Part Time",
    },
    {
      value: "full",
      label: "Full Time",
    },
  ]);
  const [roleOpt_, setRoleOpt_] = useState(null); //for selecting status

  useState(() => {
    if (selectedEmployee) {
      const attendanceArray = selectedEmployee?.attendance;

      setAttendanceHistoryArray(attendanceArray);
    }
  }, [selectedEmployee]);

  const toggleOptions = [
    { label: "P", value: "p" },
    { label: "A", value: "a" },
  ];

  const toggleHandler = (option, selecteddate, slotIndex) => {
    const value = option.target.value;
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");

    const newAttendanceHistoryArray = attendanceHistoryArray?.map((item) => {
      const { date, slots } = item;

      if (date === selecteddate) {
        if (!slots?.length) {
          return {
            ...item,
            slots: { ...slots, presence: value, updatedAt: currentTime },
          };
        } else {
          let newSlot;
          newSlot = slots?.map((sl, index) => {
            if (index === slotIndex) {
              return { ...sl, presence: value, updatedAt: currentTime };
            } else {
              return { ...sl, updatedAt: currentTime };
            }
          });

          return { ...item, slots: newSlot };
        }
      }
    });

    const filteredArray = newAttendanceHistoryArray?.filter(Boolean);

    dispatch(editEmployeeAttendance({ data: filteredArray[0], id: id }));
  };

  useEffect(() => {
    if (selectedEmployee) {
      setRows(
        AttendanceHistoryRow(
          attendanceHistoryArray,
          toggleHandler,
          toggleOptions,
          "small"
        )
      );
    }
  }, [selectedEmployee, attendanceHistoryArray]);

  const columns = [
    { name: "S.no", align: "left", width: "30px" },
    { name: "Date/Time", align: "left" },
    { name: "Date/Time (Marked)", align: "left" },
    { name: "Job Role", align: "left", minWidth: "100px" },
    { name: "Action", align: "left", minWidth: "110px" },
  ];

  const [filterQuery, setFilterQuery] = useState({
    fromDate: null,
    toDate: null,
    jobRole: null,
  });

  const resetHandler = useCallback(() => {
    setFilterQuery({
      toDate: null,
      fromDate: null,
      role: null,
    });
    setRoleOpt_(null);
    setAttendanceHistoryArray(selectedEmployee?.attendance);
  }, [selectedEmployee]);

  const filterHandler = () => {
    let filterData = selectedEmployee?.attendance.filter((item) => {
      const dateString = item?.date;
      const [day, month, year] = dateString.split("/").map(Number);
      const dateObject = new Date(year, month - 1, day);
      const timestamp = dateObject.getTime(); // This will give you the timestamp in milliseconds

      if (filterQuery.role) {
        if (filterQuery.role === "all") {
          setFilterQuery({ ...filterQuery, role: null });
        } else if (filterQuery.role === "part" && !item.slots?.length) {
          return false;
        } else if (filterQuery.role === "full" && item.slots?.length) {
          return false;
        }
      }

      if (filterQuery.fromDate) {
        if (filterQuery.fromDate.getTime() > timestamp) {
          return false; // Filter out the item if fromDate is greater than the timestamp
        }
      }

      if (filterQuery.toDate) {
        if (filterQuery.toDate.getTime() < timestamp) {
          return false;
        }
      }

      return true;
    });

    setAttendanceHistoryArray(filterData);
  };
  const {uid} = useSelector(userSelector);
  const addAttendanceHandler =  ()=>{
    const backDateAttendanceData = {
      selectedToggle: selectedToggle,
      current_role: selectedEmployee.current_role,
      date: date,
      employeeId: selectedEmployee.id
    };
    dispatch(addBackDateAttendance({formdata: backDateAttendanceData, adminId: uid}));
    setDate(null);
    setSelectedToggle(null);
  }
  const today = new Date();
  return (
    <SuiBox mb={3}>
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="end"
          p={3}
          pb={0}
        >
          <SuiTypography display="flex" alignItems="center">
            <SuiTypography variant="h6">Attendance History</SuiTypography>
            <span
              style={{
                paddingLeft: "6px",
                fontSize: "14px",
                height: "16px",
                width: "14px",
              }}
            >
              <Tooltip
                title={
                  "Below are the list of attendance history you have update, make sure it is updated"
                }
                placement="top"
                style={{ color: "#67748e", height: "16px", width: "14px" }}
              >
                <Icon color="inherit">{"help"}</Icon>
              </Tooltip>
            </span>
          </SuiTypography>
        </SuiBox>
        <SuiBox
          display="flex"
          // justifyContent="space-between"
          flexWrap="wrap"
          alignItems="end"
          style={{ padding: "0 24px" }}
        >
          <SuiBox my={1} mr={2.5}>
            <SuiTypography mb={1} variant="h6">
              To Date
            </SuiTypography>
            <DatePicker
              isClearable
              closeOnScroll={true}
              selected={filterQuery?.fromDate}
              onChange={(date) => {
                setFilterQuery({
                  ...filterQuery,
                  fromDate: date,
                });
              }}
              className="date-picker-custom-v2"
              placeholderText="To date"
            />
          </SuiBox>

          <SuiBox my={1} mr={2.5}>
            <SuiTypography mb={1} variant="h6">
              From Date
            </SuiTypography>
            <DatePicker
              isClearable
              closeOnScroll={true}
              selected={filterQuery?.toDate}
              onChange={(date) => {
                setFilterQuery({
                  ...filterQuery,
                  toDate: date,
                });
              }}
              className="date-picker-custom-v2"
              placeholderText="From date"
            />
          </SuiBox>
          <SuiBox my={1} mr={2.5}>
            <SuiTypography mb={1} variant="h6">
              Job Role
            </SuiTypography>
            <Createable
              options={roleOptions ?? []}
              value={roleOpt_}
              style={{ borderRadius: "0.5rem", fontSize: 14 }}
              inputStyle={{ width: 100, height: "1.800rem" }}
              placeholder="Job role"
              onChange={(e) => {
                setRoleOpt_(e);
                setFilterQuery({
                  ...filterQuery,
                  role: e.value,
                });
              }}
            />
          </SuiBox>

          <SuiBox mb={1} mr={2.5}>
            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              onClick={filterHandler}
            >
              Search
            </SuiButton>
          </SuiBox>

          <SuiBox mb={1} mr={2.5}>
            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              style={{
                color: "#3A416F",
              }}
              onClick={() => {
                resetHandler();
              }}
            >
              Reset
            </SuiButton>
          </SuiBox>
        </SuiBox>
        <SuiBox
          className="add-attendance"
          display="flex"
          flexWrap="wrap"
          alignItems="end"
          style={{ padding: "0 24px" }}
        >
          <SuiBox my={1} mr={2.5}>
            <SuiTypography mb={1} variant="h6">
              Select Date
            </SuiTypography>
            <DatePicker
              isClearable
              maxDate={today}
              closeOnScroll={true}
              selected={date}
              style={{width: `100%`}}
              onChange={(date) => {
                setDate(date);
              }}
              className="date-picker-custom-v2"
              placeholderText="Select date"
            />
          </SuiBox>
          <SuiBox my={1} mr={2.5}>
            <SuiTypography mb={1} variant="h6">
              Action
            </SuiTypography>
            <Createable
              options={toggleOptions ?? []}
              value={toggleOptions.find(option => option.value === selectedToggle) || null}
              style={{ borderRadius: "0.5rem", fontSize: 14, width: `100%` }}
              inputStyle={{ width: 100, height: "1.800rem" }}
              placeholder="Attendance"
              onChange={(option) => {
                setSelectedToggle(option.value);
              }}
            />
          </SuiBox>
          <SuiBox mb={1} mr={2.5}>
            <SuiButton
              component={Button}
              color="dark"
              variant="gradient"
              onClick={addAttendanceHandler}
              disabled={!date || !selectedToggle}
            >
              Add Attendance
            </SuiButton>
          </SuiBox>
        </SuiBox>
        <SuiBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loader ? (
            <SuiBox
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <TailSpin color="#17c1e8" height={60} width={60} />
            </SuiBox>
          ) : rows.length ? (
            <Table columns={columns} rows={rows} maxRows={7} />
          ) : (
            <NoData />
          )}
        </SuiBox>{" "}
      </Card>
    </SuiBox>
  );
};

export default AttendanceHistoryTable;
