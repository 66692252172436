import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useDispatch } from "react-redux";
import { setSlotIndex } from "store/features/employee";
import { styled } from "@mui/material/styles";
import { Padding } from "@mui/icons-material";

export default function ToggleButtons({
  defaultValue,
  options,
  value,
  handleChange,
  style_,
  disabled = false,
  size,
  slotNumber,
  index,
}) {
  const dispatch = useDispatch();

  const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    "&.MuiToggleButton-root": {
      padding: 4,
      fontSize: "12px",
    },
    "&.Mui-selected": {
      color: "#17C1E8",
      backgroundColor: "transparent",

      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  }));
  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      disabled={disabled}
      defaultValue={defaultValue}
      exclusive
      onChange={(option) => handleChange(option, index, slotNumber)}
      aria-label="Platform"
      style={{ ...style_ }}
      size={size}
    >
      {options?.map((item, index) => (
        <CustomToggleButton value={item?.value} key={index}>
          {item?.label}
        </CustomToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
